import React, { useState } from "react";
import { Form, Button } from 'react-bootstrap';
import axios from "../api/axiosConfig";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  if (localStorage.getItem('token')) {
    window.location.href = '/'
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    const response = await axios.post("/users/sign_in", {
        user: { email, password },
      }, {
      withCredentials: true
    });
    console.log(response);
    localStorage.setItem("token", response.data.token);
  };

  return (
    <div className="login-form-container">
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="email">
          <Form.Label>Email address</Form.Label>
          <Form.Control
            type="email"
            placeholder="Enter email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Form.Group>

        <Form.Group controlId="password">
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </Form.Group>

        <Button variant="primary" type="submit">
          Log in
        </Button>
      </Form>
    </div>
  );
}

export default Login;

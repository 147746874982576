import React, { useState } from 'react';
import * as chrono from 'chrono-node';
import axios from "../api/axiosConfig";

function TodoItem(props) {
  const [isEditing, setIsEditing] = useState(false);
  const [title, setTitle] = useState(props.todo.title);
  const [completed, setCompleted] = useState(props.todo.completed);
  const [dueDate, setDueDate] = useState(new Date(props.todo.due_date))

  function handleSaveEdit() {
    const parsedDueDate = chrono.parseDate(title);
    axios.put(`/tasks/${props.todo.id}`, {
      title: title,
      due_date: parsedDueDate
    })
    .then(response => {
      console.log(response.data);
      setIsEditing(false);
    })
    .catch(error => {
      console.log(error);
    });
  }

  function handleTitleChange(newTitle) {
    setTitle(newTitle)
  }

  function handleEdit() {
    setIsEditing(true);
  }

  function handleEnter(event) {
    if (event.key === 'Enter') {
      handleSaveEdit();
    }
  }

  function isToday(date) {
    const today = new Date();
    return date.getDate() === today.getDate() && date.getMonth() === today.getMonth();
  }

  const calculateNewDueDate = (repeat, due_date) => {
    let newDueDate;

    if (repeat === "daily") {
      newDueDate = new Date(due_date);
      newDueDate.setDate(newDueDate.getDate() + 1);
    } else if (repeat === "weekly") {
      newDueDate = new Date(due_date);
      newDueDate.setDate(newDueDate.getDate() + 7);
    } else if (repeat === "monthly") {
      newDueDate = new Date(due_date);
      newDueDate.setMonth(newDueDate.getMonth() + 1);
    }
    return newDueDate;
  };

  const completeTodo = () => {
    if (props.todo.repeat) {
      const newDueDate = calculateNewDueDate(props.todo.repeat, dueDate);
      axios.put(`/tasks/${props.todo.id}`, {
        due_date: newDueDate,
      }).then(response => {
        console.log(response.data);
        setDueDate(newDueDate);
      });
    } else {
      axios.put(`/tasks/${props.todo.id}`, {
        completed: !completed
      }).then(response => {
        console.log(response.data);
        props.handleComplete({ todo: response.data, completed: !completed });
        setCompleted(!completed);
      });
    }
  };

  if (isEditing) {
    return (
      <input
        type="text"
        className="form-control"
        value={title}
        onChange={ (event) => handleTitleChange(event.target.value)}
        onKeyDown={(event) => handleEnter(event)}
        onBlur={() => handleSaveEdit()}
      />
    );
  } else {
    return (
      <div class={`list-group-item ${completed && 'text-muted'}`}>
        <button className="complete-button btn btn-unstyled" onClick={completeTodo}>{completed ? <i className="fas fa-redo"></i> : <i className="fas fa-check"></i>}</button>

          <span class="todo-item"><span onClick={() => handleEdit(props.todo)}>{title}</span></span>
          {' '}
          {dueDate && !completed && (
            <small className="text-muted">
                {dueDate
                  ? dueDate.toLocaleString([], {
                      day: 'numeric',
                      month: 'short',
                      hour: isToday(dueDate) ? 'numeric' : undefined,
                      minute: isToday(dueDate) ? 'numeric' : undefined
                    })
                  : ''}
              </small>
            )}


      {props.handleDelete && (  <button className="complete-button btn btn-unstyled text-muted" onClick={() => (props.handleDelete(props.todo.id))}>
          <i className="fas fa-trash" />
        </button>
      )}
      </div>
    );
  }
}

export default TodoItem

import React from 'react';
import { Route } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

const PrivateRoute = ({children, ...rest}) => {
  let navigate = useNavigate();
  if (!localStorage.getItem('token')) {
    navigate("/login");
    return null;
  }
  return children;
};

export default PrivateRoute;

import React, { useEffect, useState } from 'react';
import AddTodo from './AddTodo';
import TodoItem from './TodoItem';
import axios from "../api/axiosConfig";


function TodoList() {
  const [todos, setTodos] = useState([]);
  const [completedTasks, setCompletedTasks] = useState([]);

  useEffect(() => {
    const token = localStorage.getItem("token");
    axios.get('/tasks', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(response => {
      console.log(response.data.complete);
      setTodos(response.data.incomplete);
      setCompletedTasks(response.data.completed);
    })
    .catch(error => {
      console.log(error);
    });
  }, []);

  function addTodoToList(newTodo) {
    setTodos(prevTodos => [...prevTodos, newTodo]);
  }

  const handleDelete = async (id) => {
    try {
      await axios.delete(`/tasks/${id}`);
      setTodos(todos.filter(todo => todo.id !== id));
    } catch (err) {
      console.log(err);
    }
  }

  const handleComplete = ({ todo, completed }) => {
    if (completed) {
      const newTodos = todos.filter((currentTodo) => currentTodo.id !== todo.id);
      setTodos(newTodos);
      setCompletedTasks(prevCompletedTasks => [...completedTasks, todo]);
      return null;
    }

    setCompletedTasks(prevCompletedTasks => [...prevCompletedTasks.filter((currentTodo) => currentTodo.id !== todo.id)]);
    setTodos(prevTodos => [...todos, todo]);
  }

  return (
      <div className="todo-list-container">
        <h2>To-Do List</h2>
        <ul className="todo-list">

          {todos.map(todo => (
            <TodoItem
              key={`todo-${todo.id}`}
              todo={todo}
              handleDelete={handleDelete}
              handleComplete={handleComplete}
              setRescheduled={() => {}} // reorder here?
            />
          ))}
        </ul>

        <AddTodo addTodoToList={addTodoToList} />
        <hr />

        <ul className="todo-list">

          {completedTasks.map(todo => (
            <TodoItem
              key={`todo-${todo.id}`}
              todo={todo}
              handleDelete={handleDelete}
              handleComplete={handleComplete}
            />
          ))}
        </ul>
      </div>
    );
}

export default TodoList;

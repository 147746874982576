import React, { useState, useEffect } from 'react';
import axios from "../api/axiosConfig";
import { Card, Col, Row } from 'react-bootstrap';

import TodoItem from './TodoItem';

const TasksOverview = () => {
  const [todaysTasks, setTodaysTasks] = useState([]);
  const [weeksTasks, setWeeksTasks] = useState([]);
  const [monthsTasks, setMonthsTasks] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('/tasks/overview');
        setTodaysTasks(response.data.todays_tasks);
        setWeeksTasks(response.data.weeks_tasks);
        setMonthsTasks(response.data.months_tasks);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);


  const isToday = (someDate) => {
    const today = new Date();
    return someDate.getDate() === today.getDate() &&
      someDate.getMonth() === today.getMonth() &&
      someDate.getFullYear() === today.getFullYear();
  }

  const isThisWeek = (someDate) => {
    const today = new Date();
    const weekEnd = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 7);
    return someDate >= today && someDate <= weekEnd;
  }

  const isThisMonth = (someDate) => {
    const today = new Date();
    const thirtyDaysFromNow = new Date();
    thirtyDaysFromNow.setDate(today.getDate() + 30);
    return someDate >= today && someDate <= thirtyDaysFromNow;
  }

  const setRescheduled = (todo, category) => {
    if (category === 'today') {
      const newTodos = todaysTasks.filter((currentTodo) => {
        let dueDateString;
        if (currentTodo.id === todo.id) {
          dueDateString = todo.due_date;
        }
        else {
          dueDateString = currentTodo.due_date;
        }
        const dueDate = new Date(Date.parse(dueDateString));
        return isToday(dueDate);
      });
      setTodaysTasks(newTodos);
    } else if (category === 'week') {
      const newTodos = weeksTasks.filter((currentTodo) => {
        let dueDateString;
        if (currentTodo.id === todo.id) {
          dueDateString = todo.due_date;
        }
        else {
          dueDateString = currentTodo.due_date;
        }
        const dueDate = new Date(Date.parse(dueDateString));
        return isThisWeek(dueDate);
      });
      setWeeksTasks(newTodos);
    } else if (category === 'month') {
      const newTodos = monthsTasks.filter((currentTodo) => {
        let dueDateString;
        if (currentTodo.id === todo.id) {
          dueDateString = todo.due_date;
        }
        else {
          dueDateString = currentTodo.due_date;
        }
        const dueDate = new Date(Date.parse(dueDateString));
        return isThisMonth(dueDate);
      });
      setMonthsTasks(newTodos);
    }
  };

  const setTodaysComplete = ({ todo, completed }) => {
    if (completed) {
      const newTodos = todaysTasks.filter((currentTodo) => currentTodo.id !== todo.id);
      setTodaysTasks(newTodos);
    }
  }

  const setWeeksComplete = ({ todo, completed }) => {
    console.log(todo, completed)
    if (completed) {
      const newTodos = weeksTasks.filter((currentTodo) => currentTodo.id !== todo.id);
      setWeeksTasks(newTodos);
    }
  }

  const setMonthsComplete = ({ todo, completed } ) => {
    if (completed) {
      const newTodos = monthsTasks.filter((currentTodo) => currentTodo.id !== todo.id);
      setMonthsTasks(newTodos);
    }
  }

  return (
    <div>
      <br/>
      <Row className="container-fluid mt20">
        <Col xs={12} md={4}>
          <Card>
            <Card.Body>
              <Card.Title>Today's Tasks</Card.Title>
              <ul>
                {todaysTasks.map((task) => (
                  <TodoItem
                    todo={task}
                    key={`task-${task.id}`}
                    handleComplete={setTodaysComplete}
                    setRescheduled={(todo) => setRescheduled(todo, 'today')}
                  />
                ))}
              </ul>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12} md={4}>
          <Card>
            <Card.Body>
              <Card.Title>This Week's Tasks</Card.Title>
              <ul>
                {weeksTasks.map((task) => (
                  <TodoItem
                    todo={task}
                    key={`task-${task.id}`}
                    handleComplete={setWeeksComplete}
                    setRescheduled={(todo) => setRescheduled(todo, 'week')}
                  />
                ))}
              </ul>
            </Card.Body>
          </Card>
        </Col>
        <Col className="pe-0" xs={12} md={4}>
          <Card>
            <Card.Body>
              <Card.Title>This Month's Tasks</Card.Title>
              <ul>
                {monthsTasks.map((task) => (
                  <TodoItem
                    todo={task}
                    key={`task-${task.id}`}
                    handleComplete={setMonthsComplete}
                    setRescheduled={(todo) => setRescheduled(todo, 'month')}
                  />
                ))}
              </ul>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default TasksOverview;

import React from "react";
import TasksOverview from './TasksOverview';

function Home() {
  return (
    <div>
      <TasksOverview />
    </div>
  );
}

export default Home;

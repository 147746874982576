import React, { useState }from 'react';
import { Form, Button } from 'react-bootstrap';
import * as chrono from 'chrono-node';
import axios from "../api/axiosConfig";

function AddTodo({ addTodoToList }) {
  const [title, setTitle] = useState('');
  const [repeat, setRepeat] = useState('');

  function handleChange(event) {
    setTitle(event.target.value);
  }

  function handleSubmit(event) {
    event.preventDefault();
    const parsedDueDate = chrono.parseDate(title);
    axios.post('/tasks', {
      title: title,
      due_date: parsedDueDate,
      repeat: repeat
    })
    .then(response => {
      console.log(response.data);
      addTodoToList(response.data);
      setTitle('');
      setRepeat('');
    })
    .catch(error => {
      console.log(error);
    });
  }

  return (
    <Form onSubmit={handleSubmit} className="row">
      <Form.Group className="col">
        <Form.Control type="text" placeholder="Enter title" value={title} onChange={handleChange} />
      </Form.Group>
      <select class="form-control col" value={repeat} placeholder="Enter title" onChange={(event) => setRepeat(event.target.value)}>
        <option value="">Repeat</option>
        <option value="daily">Daily</option>
        <option value="weekly">Weekly</option>
        <option value="monthly">Monthly</option>
      </select>
      <Button variant="primary" type="submit" className="col-auto pl5">
        Add
      </Button>
    </Form>


  );
}

export default AddTodo;

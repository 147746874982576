import React from 'react';

function EditTodo() {
  return (
    <div>
      edit
    </div>
  );
}

export default EditTodo;
